import React from "react"
import Image from "gatsby-image"

const ProjectPreview = ({ title, subtitle, imageData, url, github }) => (
  <div className="project-preview">
    <Image fluid={imageData} alt={title} />
    <h2>{title}</h2>
    <p>{subtitle}</p>
    <div className="project-links">
      <span>
        <a href={url} target="_blank">
          View Live
        </a>
      </span>
      <span>
        <a href={github} target="_blank">
          View Source
        </a>
      </span>
    </div>
  </div>
)

export default ProjectPreview
